(function ($, generic) {
  Drupal.behaviors.emailSignupPanelV1 = {
    attach: function (context) {
      var $template = $('.js-email-signup-panel-v1', context);

      if ($template.length < 1) {
        return null;
      }

      $template.each(function () {
        var $emailContainerNode = $(this);
        var $emailForm = $('.js-email-signup-panel__form', $emailContainerNode);
        var $emailInput = $('.js-email-signup-panel__email-field', $emailContainerNode);
        var $mobileNumberInput = $('.js-email-signup-panel__sms-field', $emailContainerNode);
        var $emailErrorMessage = $('.js-email-signup-panel-error', $emailContainerNode);
        var $smsTermsAndConditionsCheckbox = $(
          '.js-email-signup-panel__sms-terms-conditions-checkbox',
          $emailContainerNode
        );
        var $smsTermsAndConditions = $('.js-email-signup-panel-sms-terms-conditions', $emailContainerNode);
        var $emailSuccessMessage = $('.js-email-signup-panel-success', $emailContainerNode);
        var $emailAlreadySignedUpMessage = $('.js-email-signup-panel-already-signed-up', $emailContainerNode);
        var $gdprTextLink = $('.js-email-signup-panel__information', $emailContainerNode);
        var $closeIcon = $('.js-email-signup-panel__close-btn', $emailContainerNode);
        var $gdprOverlay = $('.js-email-signup-panel__gdpr-overlay', $emailContainerNode);

        $gdprTextLink.once().on('click.gdrpLink', function (event) {
          var expanded = $gdprOverlay.hasClass('expanded');

          event.preventDefault();
          $gdprOverlay.toggleClass('expanded', !expanded);
          if (!expanded) {
            $gdprOverlay.trigger('focus');
          }
        });

        $smsTermsAndConditions
          .on('focusin', function () {
            $smsTermsAndConditions.addClass('is-focused');
          })
          .on(
            'focusout',
            _.debounce(function (event) {
              var $target = $(event.relatedTarget);

              $smsTermsAndConditions.removeClass('is-focused');

              if (
                !$mobileNumberInput.val() &&
                !$smsTermsAndConditions.hasClass('is-focused') &&
                !$smsTermsAndConditionsCheckbox.is(':checked') &&
                !$target.hasClass('js-email-signup-panel__sms-field')
              ) {
                $smsTermsAndConditions.slideUp(300);
              }
            }, 300)
          );

        // Show SMS terms + conditions
        $mobileNumberInput
          .on('focusin', function () {
            $smsTermsAndConditions.slideDown(300);
          })
          .on(
            'focusout',
            _.debounce(function (event) {
              var $this = $(this);
              var $target = $(event.relatedTarget);
              var checked = $smsTermsAndConditionsCheckbox.is(':checked');

              if (
                !$this.val() &&
                !$smsTermsAndConditions.hasClass('is-focused') &&
                !$target.parents('.js-email-signup-panel-sms-terms-conditions').length &&
                !checked
              ) {
                $smsTermsAndConditions.slideUp(300);
              }
            }, 300)
          );

        $closeIcon.once().on('click.gdprClose', function (event) {
          event.preventDefault();
          $gdprOverlay.toggleClass('expanded', false);
          $gdprTextLink.focus();
        });

        $emailForm.once().submit(function (submitEvt) {
          submitEvt.preventDefault();
          $emailSuccessMessage.add($emailErrorMessage).add($emailAlreadySignedUpMessage).addClass('hidden');
          $emailInput.removeClass('error');
          $mobileNumberInput.removeClass('error');
          // Transform string into array of form elements
          var params = {};

          $.each($emailForm.serializeArray(), function (index, kv) {
            params[kv.name] = kv.value.replace('undefined', '').replace('%40', '@');
          });

          // Send the data via a json rpc call
          generic.jsonrpc.fetch({
            method: 'rpc.form',
            params: [params],
            onSuccess: function (jsonRpcResponse) {
              var response = jsonRpcResponse.getData();
              var alreadySignedUp = response.userinfo.previously_opted_in_email_promotion;

              if (alreadySignedUp) {
                $(document).trigger('email_signup:success_but_signed_up');
                $emailAlreadySignedUpMessage.removeClass('hidden');
              } else {
                $(document).trigger('email_signup:success_and_new');
                $emailSuccessMessage.removeClass('hidden');
              }

              $emailSuccessMessage.removeClass('hidden');
              $emailInput.val('');
              $mobileNumberInput.val('');
            },
            onFailure: function (jsonRpcResponse) {
              var errorObjectsArray = jsonRpcResponse.getMessages();
              var errorLength = errorObjectsArray.length;
              var errors = '';

              generic.showErrors(errorObjectsArray, null, null);
              $emailErrorMessage.removeClass('hidden');
              for (var i = 0; i < errorLength; i++) {
                var myErr = errorObjectsArray[i];

                if (myErr && myErr.key) {
                  if (
                    myErr.key === 'required_or.pc_email_address.mobile_email_address.required_or.email_signup' ||
                    myErr.key === 'required.pc_email_address.email_signup' ||
                    myErr.key === 'invalid.pc_email_address.email_signup'
                  ) {
                    errors += myErr.text + '<br/>';
                    $emailInput.addClass('error').focus();
                  }
                  if (
                    myErr.key === 'required_dependency.sms_promotions.mobile_phone.dependency.sms' ||
                    myErr.key === 'format.mobile_phone.sms'
                  ) {
                    errors += myErr.text + '<br/>';
                    $mobileNumberInput.addClass('error').focus();
                  }
                }
              }
              if (errors) {
                $emailErrorMessage.html(errors);
              } else {
                $emailErrorMessage.removeClass('hidden');
              }
            }
          });
        });
      });
    }
  };
})((window.jQuery = window.jQuery || function () {}), (window.generic = window.generic || {}));
